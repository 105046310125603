exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/App.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-education-gamma-ray-burst-unit-js": () => import("./../../../src/pages/education/gammaRayBurstUnit.js" /* webpackChunkName: "component---src-pages-education-gamma-ray-burst-unit-js" */),
  "component---src-pages-education-gems-js": () => import("./../../../src/pages/education/gems.js" /* webpackChunkName: "component---src-pages-education-gems-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-education-newton-js": () => import("./../../../src/pages/education/newton.js" /* webpackChunkName: "component---src-pages-education-newton-js" */),
  "component---src-pages-education-newton-standards-js": () => import("./../../../src/pages/education/newtonStandards.js" /* webpackChunkName: "component---src-pages-education-newton-standards-js" */),
  "component---src-pages-gamma-ray-bpp-js": () => import("./../../../src/pages/gammaRayBPP.js" /* webpackChunkName: "component---src-pages-gamma-ray-bpp-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-media-subpages-blackhole-js": () => import("./../../../src/pages/mediaSubpages/blackhole.js" /* webpackChunkName: "component---src-pages-media-subpages-blackhole-js" */),
  "component---src-pages-media-subpages-journey-to-blackhole-js": () => import("./../../../src/pages/mediaSubpages/journeyToBlackhole.js" /* webpackChunkName: "component---src-pages-media-subpages-journey-to-blackhole-js" */),
  "component---src-pages-media-subpages-media-video-page-js": () => import("./../../../src/pages/mediaSubpages/mediaVideoPage.js" /* webpackChunkName: "component---src-pages-media-subpages-media-video-page-js" */),
  "component---src-pages-media-subpages-mini-jet-js": () => import("./../../../src/pages/mediaSubpages/miniJet.js" /* webpackChunkName: "component---src-pages-media-subpages-mini-jet-js" */),
  "component---src-pages-media-subpages-monster-blackhole-js": () => import("./../../../src/pages/mediaSubpages/monsterBlackhole.js" /* webpackChunkName: "component---src-pages-media-subpages-monster-blackhole-js" */),
  "component---src-pages-media-subpages-powerpoints-js": () => import("./../../../src/pages/mediaSubpages/powerpoints.js" /* webpackChunkName: "component---src-pages-media-subpages-powerpoints-js" */),
  "component---src-pages-media-subpages-swift-song-js": () => import("./../../../src/pages/mediaSubpages/swiftSong.js" /* webpackChunkName: "component---src-pages-media-subpages-swift-song-js" */),
  "component---src-pages-media-subpages-swift-timeline-js": () => import("./../../../src/pages/mediaSubpages/swiftTimeline.js" /* webpackChunkName: "component---src-pages-media-subpages-swift-timeline-js" */),
  "component---src-pages-media-subpages-swift-video-js": () => import("./../../../src/pages/mediaSubpages/swiftVideo.js" /* webpackChunkName: "component---src-pages-media-subpages-swift-video-js" */),
  "component---src-pages-media-subpages-timeline-subpages-ancient-universe-js": () => import("./../../../src/pages/mediaSubpages/timelineSubpages/ancientUniverse.js" /* webpackChunkName: "component---src-pages-media-subpages-timeline-subpages-ancient-universe-js" */),
  "component---src-pages-media-subpages-timeline-subpages-bat-js": () => import("./../../../src/pages/mediaSubpages/timelineSubpages/bat.js" /* webpackChunkName: "component---src-pages-media-subpages-timeline-subpages-bat-js" */),
  "component---src-pages-media-subpages-timeline-subpages-birth-death-star-js": () => import("./../../../src/pages/mediaSubpages/timelineSubpages/birthDeathStar.js" /* webpackChunkName: "component---src-pages-media-subpages-timeline-subpages-birth-death-star-js" */),
  "component---src-pages-media-subpages-timeline-subpages-extreme-clean-js": () => import("./../../../src/pages/mediaSubpages/timelineSubpages/extremeClean.js" /* webpackChunkName: "component---src-pages-media-subpages-timeline-subpages-extreme-clean-js" */),
  "component---src-pages-media-subpages-timeline-subpages-full-spectrum-js": () => import("./../../../src/pages/mediaSubpages/timelineSubpages/fullSpectrum.js" /* webpackChunkName: "component---src-pages-media-subpages-timeline-subpages-full-spectrum-js" */),
  "component---src-pages-media-subpages-timeline-subpages-gamma-and-swift-js": () => import("./../../../src/pages/mediaSubpages/timelineSubpages/gammaAndSwift.js" /* webpackChunkName: "component---src-pages-media-subpages-timeline-subpages-gamma-and-swift-js" */),
  "component---src-pages-media-subpages-timeline-subpages-gamma-ray-b-js": () => import("./../../../src/pages/mediaSubpages/timelineSubpages/gammaRayB.js" /* webpackChunkName: "component---src-pages-media-subpages-timeline-subpages-gamma-ray-b-js" */),
  "component---src-pages-media-subpages-timeline-subpages-hunt-for-gamma-js": () => import("./../../../src/pages/mediaSubpages/timelineSubpages/huntForGamma.js" /* webpackChunkName: "component---src-pages-media-subpages-timeline-subpages-hunt-for-gamma-js" */),
  "component---src-pages-media-subpages-timeline-subpages-hunting-gamma-js": () => import("./../../../src/pages/mediaSubpages/timelineSubpages/huntingGamma.js" /* webpackChunkName: "component---src-pages-media-subpages-timeline-subpages-hunting-gamma-js" */),
  "component---src-pages-media-subpages-timeline-subpages-new-eye-js": () => import("./../../../src/pages/mediaSubpages/timelineSubpages/newEye.js" /* webpackChunkName: "component---src-pages-media-subpages-timeline-subpages-new-eye-js" */),
  "component---src-pages-media-subpages-timeline-subpages-swift-mission-js": () => import("./../../../src/pages/mediaSubpages/timelineSubpages/swiftMission.js" /* webpackChunkName: "component---src-pages-media-subpages-timeline-subpages-swift-mission-js" */),
  "component---src-pages-media-subpages-timeline-summary-js": () => import("./../../../src/pages/mediaSubpages/timelineSummary.js" /* webpackChunkName: "component---src-pages-media-subpages-timeline-summary-js" */),
  "component---src-pages-ppt-csta-waves-js": () => import("./../../../src/pages/ppt/CSTAWaves.js" /* webpackChunkName: "component---src-pages-ppt-csta-waves-js" */),
  "component---src-pages-ppt-display-js": () => import("./../../../src/pages/pptDisplay.js" /* webpackChunkName: "component---src-pages-ppt-display-js" */),
  "component---src-pages-ppt-electromagnetic-spectrum-js": () => import("./../../../src/pages/ppt/electromagneticSpectrum.js" /* webpackChunkName: "component---src-pages-ppt-electromagnetic-spectrum-js" */),
  "component---src-pages-ppt-epo-1999-js": () => import("./../../../src/pages/ppt/EPO1999.js" /* webpackChunkName: "component---src-pages-ppt-epo-1999-js" */),
  "component---src-pages-ppt-epo-2000-js": () => import("./../../../src/pages/ppt/EPO2000.js" /* webpackChunkName: "component---src-pages-ppt-epo-2000-js" */),
  "component---src-pages-ppt-extreme-gamma-js": () => import("./../../../src/pages/ppt/ExtremeGamma.js" /* webpackChunkName: "component---src-pages-ppt-extreme-gamma-js" */),
  "component---src-pages-ppt-gamma-ray-bpp-js": () => import("./../../../src/pages/ppt/gammaRayBPP.js" /* webpackChunkName: "component---src-pages-ppt-gamma-ray-bpp-js" */),
  "component---src-pages-ppt-gems-2003-js": () => import("./../../../src/pages/ppt/gems2003.js" /* webpackChunkName: "component---src-pages-ppt-gems-2003-js" */),
  "component---src-pages-ppt-gems-pp-js": () => import("./../../../src/pages/ppt/gemsPP.js" /* webpackChunkName: "component---src-pages-ppt-gems-pp-js" */),
  "component---src-pages-ppt-gra-js": () => import("./../../../src/pages/ppt/GRA.js" /* webpackChunkName: "component---src-pages-ppt-gra-js" */),
  "component---src-pages-ppt-grb-explorer-js": () => import("./../../../src/pages/ppt/GRBExplorer.js" /* webpackChunkName: "component---src-pages-ppt-grb-explorer-js" */),
  "component---src-pages-ppt-mother-js": () => import("./../../../src/pages/ppt/mother.js" /* webpackChunkName: "component---src-pages-ppt-mother-js" */),
  "component---src-pages-ppt-newtons-2008-pp-js": () => import("./../../../src/pages/ppt/newtons2008PP.js" /* webpackChunkName: "component---src-pages-ppt-newtons-2008-pp-js" */),
  "component---src-pages-ppt-public-2003-js": () => import("./../../../src/pages/ppt/public2003.js" /* webpackChunkName: "component---src-pages-ppt-public-2003-js" */),
  "component---src-pages-ppt-road-to-the-internet-js": () => import("./../../../src/pages/ppt/roadToTheInternet.js" /* webpackChunkName: "component---src-pages-ppt-road-to-the-internet-js" */),
  "component---src-pages-ppt-seu-js": () => import("./../../../src/pages/ppt/SEU.js" /* webpackChunkName: "component---src-pages-ppt-seu-js" */),
  "component---src-pages-ppt-supernova-js": () => import("./../../../src/pages/ppt/supernova.js" /* webpackChunkName: "component---src-pages-ppt-supernova-js" */),
  "component---src-pages-ppt-sw-e-cat-sonoma-whitlock-js": () => import("./../../../src/pages/ppt/SwECatSonomaWhitlock.js" /* webpackChunkName: "component---src-pages-ppt-sw-e-cat-sonoma-whitlock-js" */),
  "component---src-pages-ppt-swec-2003-js": () => import("./../../../src/pages/ppt/swec2003.js" /* webpackChunkName: "component---src-pages-ppt-swec-2003-js" */),
  "component---src-pages-ppt-swecatsonoma-cominsky-js": () => import("./../../../src/pages/ppt/swecatsonomaCominsky.js" /* webpackChunkName: "component---src-pages-ppt-swecatsonoma-cominsky-js" */),
  "component---src-pages-ppt-swift-2002-js": () => import("./../../../src/pages/ppt/swift2002.js" /* webpackChunkName: "component---src-pages-ppt-swift-2002-js" */),
  "component---src-pages-ppt-swift-82002-js": () => import("./../../../src/pages/ppt/swift82002.js" /* webpackChunkName: "component---src-pages-ppt-swift-82002-js" */),
  "component---src-pages-ppt-swift-and-telescope-js": () => import("./../../../src/pages/ppt/swiftAndTelescope.js" /* webpackChunkName: "component---src-pages-ppt-swift-and-telescope-js" */),
  "component---src-pages-ppt-swift-ep-oat-sonoma-gsfc-js": () => import("./../../../src/pages/ppt/swiftEPOatSonomaGSFC.js" /* webpackChunkName: "component---src-pages-ppt-swift-ep-oat-sonoma-gsfc-js" */),
  "component---src-pages-ppt-swift-ep-oat-sonoma-report-js": () => import("./../../../src/pages/ppt/SwiftEPOatSonomaReport.js" /* webpackChunkName: "component---src-pages-ppt-swift-ep-oat-sonoma-report-js" */),
  "component---src-pages-ppt-swift-epo-2003-js": () => import("./../../../src/pages/ppt/swiftEPO2003.js" /* webpackChunkName: "component---src-pages-ppt-swift-epo-2003-js" */),
  "component---src-pages-ppt-swift-epo-2008-js": () => import("./../../../src/pages/ppt/swiftEPO2008.js" /* webpackChunkName: "component---src-pages-ppt-swift-epo-2008-js" */),
  "component---src-pages-ppt-swift-viewof-the-universe-js": () => import("./../../../src/pages/ppt/swiftViewofTheUniverse.js" /* webpackChunkName: "component---src-pages-ppt-swift-viewof-the-universe-js" */),
  "component---src-pages-ppt-universe-nasa-js": () => import("./../../../src/pages/ppt/universeNASA.js" /* webpackChunkName: "component---src-pages-ppt-universe-nasa-js" */),
  "component---src-pages-ppt-waves-light-js": () => import("./../../../src/pages/ppt/wavesLight.js" /* webpackChunkName: "component---src-pages-ppt-waves-light-js" */),
  "component---src-pages-resource-subpages-brochure-2001-js": () => import("./../../../src/pages/resourceSubpages/brochure2001.js" /* webpackChunkName: "component---src-pages-resource-subpages-brochure-2001-js" */),
  "component---src-pages-resource-subpages-fact-sheet-js": () => import("./../../../src/pages/resourceSubpages/factSheet.js" /* webpackChunkName: "component---src-pages-resource-subpages-fact-sheet-js" */),
  "component---src-pages-resource-subpages-glider-js": () => import("./../../../src/pages/resourceSubpages/glider.js" /* webpackChunkName: "component---src-pages-resource-subpages-glider-js" */),
  "component---src-pages-resource-subpages-launch-fact-sheet-js": () => import("./../../../src/pages/resourceSubpages/launchFactSheet.js" /* webpackChunkName: "component---src-pages-resource-subpages-launch-fact-sheet-js" */),
  "component---src-pages-resource-subpages-resource-page-js": () => import("./../../../src/pages/resourceSubpages/resourcePage.js" /* webpackChunkName: "component---src-pages-resource-subpages-resource-page-js" */),
  "component---src-pages-resource-subpages-satellite-model-js": () => import("./../../../src/pages/resourceSubpages/satelliteModel.js" /* webpackChunkName: "component---src-pages-resource-subpages-satellite-model-js" */),
  "component---src-pages-resource-subpages-sticker-js": () => import("./../../../src/pages/resourceSubpages/sticker.js" /* webpackChunkName: "component---src-pages-resource-subpages-sticker-js" */),
  "component---src-pages-resource-subpages-swift-litho-js": () => import("./../../../src/pages/resourceSubpages/swiftLitho.js" /* webpackChunkName: "component---src-pages-resource-subpages-swift-litho-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

